export const timeOperations = {
  methods: {
    calculateTotalDuration (hours, minutes) {
      var hours_to_add = Math.trunc(minutes / 60)
      var total_hours = hours + hours_to_add
      var minutes_left = minutes - (hours_to_add * 60)
      return {
        'hours': total_hours,
        'minutes': minutes_left
      }
    },
    calculateTotalDurationWithDays (hours, minutes) {
      var hours_to_add = Math.trunc(minutes / 60)
      var total_hours = hours + hours_to_add
      var minutes_left = minutes - (hours_to_add * 60)

      var days = Math.trunc(total_hours / 24)
      var hours_left = total_hours - (days * 24)
      return {
        'days': days,
        'hours': hours_left,
        'minutes': minutes_left
      }
    },
    timeToDecimal (hours, minutes) {
      var newHours = hours
      var newMinutes = minutes
      if (minutes >= 60) {
        newHours += Math.floor(minutes/60)
        newMinutes = minutes % 60
      }
      var dec = parseInt((newMinutes/6)*10, 10);
      return parseFloat(parseInt(newHours, 10) + '.' + (dec<10?'0':'') + dec);
    }
  }
}


export const fileOperations = {
  methods: {
    sizeFormatter(size) {
      // #2**10 = 1024
      const power = 2**10
      var n = 0
      var powerN = {0 : 'B', 1: 'KB', 2: 'MB', 3: 'GB', 4: 'TB'}
      while (size > power) {
          size /=  power
          n += 1
      }
      size = Math.round(size * 10) / 10

      return size.toString() + ' ' + powerN[n]
    }
  }
}
