const CountryCodes = require('country-code-info');


export default {
  methods: {

    tvaRender(tva) {
      switch (tva) {
        case '20.0':
          return '20% TVA FR'
        case '10.0':
          return '10% TVA FR'
        case '5.5':
          return '5,5% TVA FR'
        case '2.1':
          return '2,1% TVA FR'
        case '0.9':
          return '0,9% TVA FR'
        case '0.0':
          return 'Pas de TVA FR'
      }
      return 'Erreur'
    },

    fraisCategoryRender(category) {
      switch (category) {
        case 'REPAS':
          return 'Repas'
        case 'TRAJET':
          return 'Trajet'
        case 'LOGEMENT':
          return 'Logement'
        case 'AUTRE':
          return 'Autre'
      }
      return 'Erreur'
    },

    modalitesPaiementRender(modalites) {
      switch (modalites) {
        case 'VIR':
          return 'Virement'
        case 'CB':
          return 'Carte bancaire'
        case 'CASH':
          return 'Espèces'
        case 'CHEQ':
          return 'Chèque '
      }
      return 'Erreur'
    },

    companyTypeRender(type) {
      if (type) {
        return 'Entreprise'
      }
      else {
        return 'Particulier'
      }
    },

    formatThousandSeparatorNumber(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },

    collaborateurDocumentAskingsStateRender(state) {
      switch (state) {
        case 'CREATED':
          return 'Crée'
        case 'TRANSFERED':
          return 'Document transmis ou mis à jour'
        case 'REFUSED':
          return 'Refusé'
        case 'VALIDATED':
          return 'Validé'
      }
      return 'Erreur'
    },

    clientDocumentAskingsStateRender(state) {
      switch (state) {
        case 'CREATED':
          return 'Documents à charger'
        case 'TRANSFERED':
          return 'En cours de validation'
        case 'REFUSED':
          return 'Refusé'
        case 'VALIDATED':
          return 'Validé'
      }
      return 'Erreur'
    },

    returnPhoneDial(countryCode) {
      var phone_result = CountryCodes.findCountry({'a2': countryCode});
      if (phone_result) {
        return phone_result.dial
      }
      else {
        return '00'
      }
    },

    userArrayContains(array, value) {
      var newArray = []
      for (var i = 0; i < array.length; i++) {
        newArray.push(array[i].name)
      }
      var is_contained = false
      if (newArray.indexOf(value) > -1) {
        is_contained = true
      }
      return is_contained
    },


    differenceInDays(difference) {
      return parseInt(difference/86400)
    },

    onlinePaiementsFrequenceRelanceRender(state) {
      switch (state) {
        case 'WEEK':
          return 'Hebdomadaire'
        case 'TWO_WEEKS':
          return 'Toutes les deux semaines'
        case 'MONTH':
          return 'Mensuelle'
      }
      return 'Erreur'
    },
  }
}
