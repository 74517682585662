export default {
  methods: {
    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/pages/login')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    },
  }
};
