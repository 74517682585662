<template>
  <div class="CollaborateurDocumentAskingProfile">

    <!-- REMOVE DOCUMENT ASKING MODAL -->
    <CModal
      :show.sync="isDeleteDocumentAsking"
      :no-close-on-backdrop="true"
      title="Supprimer cette demande"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          Attention, si vous supprimez cette demande de documents, <strong>tous les documents déjà chargés seront également <span class="text-danger">supprimés</span>.</strong>
        </CCol>
      </CRow>
      <CRow class="mt-3">
        <CCol class="text-center">
          <CButton
            :color="deleteDocumentAskingButtonStyle"
            shape="pill" block class="px-4"
            @click='deleteDocumentAsking'
            :disabled="deleteDocumentAskingInProcess">
            <CSpinner size="sm" label="activate user spinner" v-if="deleteDocumentAskingInProcess"></CSpinner>
            {{ deleteDocumentAskingButtonText }}
          </CButton>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="isDeleteDocumentAsking = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- VALIDATE DOCUMENT ASKING -->
    <CModal
      :show.sync="isDocumentAskingValidating"
      :no-close-on-backdrop="true"
      title="Valider les documents"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          Vous pouvez marquer cette demande de documents comme validée et les documents bien réceptionnés.
          Votre client en sera informé.
        </CCol>
      </CRow>


      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            :color="validateDocumentAskingButtonStyle"
            shape="pill" block class="px-4"
            :disabled="validateDocumentAskingInProcess"
            @click='validateDocumentAsking'>
            <CSpinner size="sm" label="validate document asking spinner" v-if="validateDocumentAskingInProcess"></CSpinner>
            {{ validateDocumentAskingButtonText }}
          </CButton>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="isDocumentAskingValidating = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <!-- VALIDATE DOCUMENT ASKING -->
    <CModal
      :show.sync="isDocumentAskingRefusing"
      :no-close-on-backdrop="true"
      title="Refuser les documents"
      size="lg"
      color="dark"
    >
    <CForm class="mt-2">
      <CTextarea
        label="Votre message de refus"
        v-model="refuseDocumentAskingMessage" type="text"
        rows="3"
        maxlength="500"
        placeholder="Message de refus"
        @input="$v.refuseDocumentAskingMessage.$touch()"
        :isValid="$v.refuseDocumentAskingMessage.$dirty ? !$v.refuseDocumentAskingMessage.$error : null"
      >
      </CTextarea>
      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            :color="refuseDocumentAskingButtonStyle"
            shape="pill" block class="px-4"
            :disabled="$v.refuseDocumentAskingMessage.$invalid || refuseDocumentAskingInProcess"
            @click='refuseDocumentAsking'>
            <CSpinner size="sm" label="refuse document asking spinner" v-if="refuseDocumentAskingInProcess"></CSpinner>
            {{ refuseDocumentAskingButtonText }}
          </CButton>
        </CCol>
      </CRow>
    </CForm>

      <template #footer>
        <CButton @click="isDocumentAskingRefusing = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>
    
    <CRow class="mb-3">
      <CCol sm="6">
        <h1> Demande de document - {{documentAsking.name}} </h1>
      </CCol>
      <CCol sm="6" class="text-right">
        <CButton
          class="ml-2"
          square
          size="sm"
          @click="isDeleteDocumentAsking = true;">
          <CIcon class="text-danger" name="cil-trash"/>
        </CButton>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardBody>

        <CRow class="mt-2 mb-2">
          <CCol>
            <strong style="font-size: 1.1em;"> Statut de la demande  :<br>
              <span v-if="documentAsking.state == 'CREATED'" class="text-primary" style="vertical-align: middle;">
                <CIcon name="cil-pencil" size="lg"/>
                <span class="ml-1">Documents à charger par le client</span>
              </span>
              <span v-if="documentAsking.state == 'TRANSFERED'" class="text-dark" style="vertical-align: middle;">
                <CIcon name="cil-clock" size="lg"/>
                <span class="ml-1">Documents chargés par le client, à refuser ou valider</span>
              </span>
              <span v-if="documentAsking.state == 'REFUSED'" class="text-danger" style="vertical-align: middle;">
                <CIcon name="cil-x-circle" size="lg"/>
                <span class="ml-1">Documents incomplets, votre client doit ajouter ou modifier ses documents</span>
              </span>
              <span v-if="documentAsking.state == 'VALIDATED'" class="text-success" style="vertical-align: middle;">
                <CIcon name="cil-check-circle" size="lg"/>
                <span class="ml-1">Documents récupérés et validés</span>
              </span>
            </strong>
          </CCol>
        </CRow>
        <CRow v-if="documentAsking.state == 'REFUSED'">
          <CCol>
            <em class="text-danger"> {{documentAsking.refused_message}} </em>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol>
            <span>Mission : </span>
            <strong> {{ documentAsking.mission.name }} </strong>
          </CCol>
        </CRow>


        <CRow class="mb-3">
          <CCol>
            <span>Description :
              <em v-if="documentAsking.description">
                {{ documentAsking.description }}
              </em>
              <em v-else>
                Aucune
              </em>
             </span>
          </CCol>
        </CRow>
        <CRow v-if="documentAsking.state == 'TRANSFERED'">
          <CCol sm="6" class="text-center">
            <CButton
            color="outline-success"
            shape="pill" class="px-4"
            @click='isDocumentAskingValidating = true'>
              Marquer la demande comme validée
            </CButton>
          </CCol>
          <CCol sm="6" class="text-center">
            <CButton
            color="outline-danger"
            shape="pill" class="px-4"
            @click='isDocumentAskingRefusing = true'>
              Marquer la demande comme incomplète
            </CButton>
          </CCol>
        </CRow>
        <CRow v-if="documentAsking.state == 'REFUSED'">
          <CCol class="text-center">
            <CButton
            color="outline-success"
            shape="pill" class="px-4"
            @click='isDocumentAskingValidating = true'>
              Marquer la demande comme validée
            </CButton>
          </CCol>
        </CRow>
        <CRow v-if="documentAsking.state == 'VALIDATED'">
          <CCol class="text-center">
            <strong class="text-success">Cette demande de document a déjà été marquée comme validée</strong>
          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Documents</strong>
      </CCardHeader>
      <CCardBody>

        <CDataTable
          :items="documentsRender"
          :fields="documentsRenderFields"
          :noItemsView='{ noResults: "Aucun document", noItems: "Aucun document" }'
        >
        <template #file_size="{item}">
          <td>
            {{ sizeFormatter(item.file_size) }}
          </td>
        </template>

        <template #actions="{item}">
          <CButton
            class="ml-2"
            square
            size="lg"
            @click="downloadClientDocument(item.id, item.file_name)">
            <CIcon size="lg" class="text-dark" name="cil-cloud-download"/>
          </CButton>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun document chargé par votre client
            </CCol>
          </CRow>
        </template>

        </CDataTable>
      </CCardBody>
    </CCard>


  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'
import userMixins from '@/mixins/userMixins'
import renderMixins from '@/mixins/renderMixins'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { fileOperations } from '@/mixins/utilMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'CollaborateurDocumentAskingProfile',
  components: {
    Loading,
  },
  mixins: [
    renderMixins,
    fileOperations,
    validationMixin,
    userMixins
  ],
  data: function () {
    return {

      documentAsking: {
        name: '',
        description: '',
        state: 'CREATED',
        mission: {
          name: ''
        }
      },
      isDocumentAskingLoading: false,

      isDeleteDocumentAsking: false,

      deleteDocumentAskingButtonText: 'Confirmer la suppression',
      deleteDocumentAskingButtonStyle: 'outline-danger',
      deleteDocumentAskingInProcess: false,

      // ------------- DOCUMENTS -----------
      documents: [],
      documentsRender: [],
      documentsRenderFields: [
        { key: "file_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "file_size", label: "Taille", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'ui-helper-center', sortable: true},
      ],
      isAllDocumentsLoading: false,

      isDocumentAskingValidating: false,
      validateDocumentAskingButtonText: "Valider la demande",
      validateDocumentAskingButtonStyle: "outline-success",
      validateDocumentAskingInProcess: false,

      isDocumentAskingRefusing: false,
      refuseDocumentAskingMessage: '',
      refuseDocumentAskingButtonText: "Marquer comme incomplet",
      refuseDocumentAskingButtonStyle: "outline-danger",
      refuseDocumentAskingInProcess: false,


    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isDocumentAskingLoading || this.isAllDocumentsLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getDocumentAsking()
    this.getAllDocuments()
  },
  watch: {
    documents: function (newDocuments) {
      if (newDocuments.length == 0) {
        this.documentsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newDocuments.length; i++) {
          final_array.push(
            {
              'id': newDocuments[i].id,
              'file_name': newDocuments[i].file_name,
              'file_size': newDocuments[i].file_size,
            }
          )
        }
        this.documentsRender = final_array
      }
    }
  },
  validations: {
    refuseDocumentAskingMessage: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(499)
    }
  },

  methods: {
    // ----------------- GETTERS ---------------------------------------
    getDocumentAsking () {
      this.isDocumentAskingLoading = true
      apiUserConnected.getDocumentAsking(this.token, this.$route.params.document_asking_pk)
      .then((result) => {
        this.documentAsking = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDocumentAskingLoading = false
      })
    },

    getAllDocuments () {
      this.isAllDocumentsLoading = true
      apiUserConnected.getAllDocuments(this.token, this.$route.params.document_asking_pk)
      .then((result) => {
        this.documents = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllDocumentsLoading = false
      })
    },

    // -------------- SETTERS ---------------------------------
    validateDocumentAsking () {
      apiUserConnected.validateDocumentAsking(this.token, this.$route.params.document_asking_pk)
      .then(() => {
        this.getDocumentAsking()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDocumentAskingValidating = false
      })
    },

    refuseDocumentAsking () {
      apiUserConnected.refuseDocumentAsking(this.token, this.$route.params.document_asking_pk, this.refuseDocumentAskingMessage)
      .then(() => {
        this.getDocumentAsking()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDocumentAskingRefusing = false
      })
    },
    deleteDocumentAsking () {
      this.deleteDocumentAskingInProcess = true
      this.deleteDocumentAskingButtonText = "Suppression en cours"
      this.deleteDocumentAskingButtonStyle = 'danger'
      apiUserConnected.deleteDocumentAsking(this.token, this.$route.params.document_asking_pk)
      .then(() => {
        this.$router.push('/mission/' + this.documentAsking.mission.id)
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDeleteDocumentAsking =false
        this.deleteDocumentAskingInProcess = false
        this.deleteDocumentAskingButtonText = "Confirmer la suppression"
        this.deleteDocumentAskingButtonStyle = 'outline-danger'
      })
    },

    // --------------- DOCUMENTS ----------------------
    downloadClientDocument (document_id, document_name) {
      apiUserConnected.downloadClientDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },
  }
}
</script>
